export const redirectTo = (url: string) => {
    window.location.assign(url);
};

export const getLocation = () => {
    if (window.location.pathname.includes('/inspiration')) return 'inspiration';
    if (window.location.pathname.includes('/discover')) return 'discover';
    return 'unknown';
};

export const isLoadingState = () => {
    return window.location.pathname === '/discover' && window.location.search === '?loading=true';
};

export const getCSRFToken = () => {
    const csrf = document.querySelector('meta[name="csrf-token"]');
    if (!csrf) {
        return '';
    }
    return (csrf as HTMLMetaElement).content;
};
