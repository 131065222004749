import styled from 'styled-components';
import { Box } from '@99designs/emu';
import { mediaQuery } from '@99designs/emu/css';
import { borders, colors, spaces } from '@99designs/emu/settings';
import { IconLike1 } from '@99designs/icons';
import { InspirationData } from '../../lib/FeatureInspirationCollector';

export type OnItemRemove = (designId: string) => void;

export interface InspirationItemProps {
    inspirationData?: InspirationData;
    onItemRemove: OnItemRemove;
}

const DesignTile = styled.div`
    border: ${borders.content};
    border-radius: 5px;
    height: 60px;
    position: relative;
    width: 60px;
    ${mediaQuery.minimum('normal')} {
        height: 80px;
        width: 80px;
    }
    img {
        border-radius: 5px;
    }
`;

const DesignTilePlaceHolder = styled(DesignTile)`
    border: 1px dashed ${colors.grey500};
    display: grid;
    place-items: center;
`;

const DesignTileWrapper = styled(Box)`
    margin: 0 2px;
    ${mediaQuery.minimum('normal')} {
        margin: 0 ${spaces['0.25bu']};
    }
`;

const RemoveItem = styled.button`
    background-color: ${colors.grey800};
    border: 1px solid ${colors.white};
    border-radius: 50%;
    color: ${colors.white};
    cursor: pointer;
    height: 20px;
    position: absolute;
    right: 2px;
    top: 2px;
    width: 20px;
    z-index: 1;

    &:before,
    &:after {
        background-color: ${colors.white};
        content: '';
        height: 60%;
        left: calc(50% - 0.0625em);
        position: absolute;
        transform: rotate(45deg);
        top: 20%;
        width: 0.125em;
    }

    &:after {
        transform: rotate(-45deg);
    }
`;

const InspirationItem = ({ inspirationData, onItemRemove }: InspirationItemProps) => {
    return (
        <DesignTileWrapper role="listitem">
            {inspirationData ? (
                <DesignTile>
                    <img alt={inspirationData.designAlt} src={inspirationData.designSrc} />
                    <RemoveItem onClick={() => onItemRemove(inspirationData.designId)} />
                </DesignTile>
            ) : (
                <DesignTilePlaceHolder>
                    <IconLike1 color={colors.grey300} />
                </DesignTilePlaceHolder>
            )}
        </DesignTileWrapper>
    );
};

export default InspirationItem;
