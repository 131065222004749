import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import Bugsnag from '@bugsnag/js';
import styled, { css } from 'styled-components';
import { Button } from '@99designs/emu';
import { mediaQuery } from '@99designs/emu/css';
import { breakpoints, colors, spaces, typography } from '@99designs/emu/settings';
import { IconDown1, IconUp1 } from '@99designs/icons';
import InspirationItemsContainer from '../components/InspirationItemsContainer';
import TooltipPrompt from '../components/TooltipPrompt';
import { getInspirations, getMaxDesignsInCollector, saveInspiration } from '../util/DataStore';
import { getCSRFToken, getLocation, isLoadingState, redirectTo } from '../util/helpers';
import {
    trackClickedSavedFavorites,
    trackCollectorToggleAction,
    trackSaveFavoritePrompt,
    trackSavedFavoriteCollection,
    trackUpdateCollector,
} from '../util/trackingEvents';
import { DESIGN_LIKE_UPDATED, INSPIRATION_COLLECTOR_UPDATED } from './InspirationEvents';

const Wrapper = styled.div<{ isMinimized: boolean }>`
    background: ${colors.white};
    border-radius: 8px 8px 0 0;
    bottom: 0;
    box-shadow: 0 -7px 21px 0 rgba(0, 0, 0, 0.1);
    position: fixed;
    width: 100%;
    z-index: 11;

    ${mediaQuery.minimum('normal')} {
        padding: 14px ${spaces['2bu']};
    }
    ${mediaQuery.only('medium')} {
        padding: 14px ${spaces['1.5bu']};
    }
    ${mediaQuery.only('small')} {
        padding: 14px ${spaces['0.75bu']} ${spaces['0.75bu']} ${spaces['0.75bu']};
    }
    ${mediaQuery.minimum('medium')} {
        right: 0;
    }

    ${({ isMinimized }) =>
        isMinimized &&
        css`
            height: 45px;
            margin-right: ${spaces['2bu']};

            ${mediaQuery.minimum('medium')} {
                width: 240px;
            }
        `}
`;

const InspirationCollectorContainer = styled.div<{ isMinimized: boolean }>`
    max-width: 1240px;
    margin: 0 auto;
    grid-template-areas:
        'title minimize-icon-container'
        'design-container tooltip-wrapper';

    ${({ isMinimized }) =>
        isMinimized &&
        css`
            ${mediaQuery.minimum('medium')} {
                display: grid;
                grid-template-rows: auto;
                grid-template-columns: 80% 1fr;
            }
        `}

    ${({ isMinimized }) =>
        !isMinimized &&
        css`
            display: grid;
            grid-template-rows: auto;
            grid-template-columns: 80% 1fr;

            ${mediaQuery.only('large')} {
                grid-template-areas:
                    'title .'
                    'design-container tooltip-wrapper';
            }

            ${mediaQuery.only('medium')} {
                grid-template-columns: 70% 1fr;

                grid-template-areas:
                    '. minimize-icon-container'
                    'title tooltip-wrapper'
                    'design-container design-container';
            }

            ${mediaQuery.only('small')} {
                grid-template-columns: 45% 1fr;

                grid-template-areas:
                    '. minimize-icon-container'
                    'title tooltip-wrapper'
                    'design-container design-container';
            }
        `}
`;

const DesignContainer = styled.div<{ isMinimized: boolean }>`
    grid-area: design-container;

    ${mediaQuery.minimum('normal')} {
        margin-top: ${spaces['0.75bu']};
    }

    ${({ isMinimized }) =>
        isMinimized &&
        css`
            display: none;
        `}
`;

const MinimizeIconContainer = styled.div<{ isMinimized: boolean }>`
    grid-area: minimize-icon-container;
    line-height: 0;
    text-align: right;

    ${mediaQuery.only('large')} {
        position: absolute;
        right: ${spaces['1bu']};
    }

    ${({ isMinimized }) =>
        isMinimized &&
        css`
            bottom: 10px;
            position: absolute;
            right: 25px;
        `}
`;

const TooltipWrapper = styled.div`
    display: flex;
    grid-area: tooltip-wrapper;
    position: relative;
`;

const SaveFavoritesButton = styled(Button)<{ $isMinimized: boolean }>`
    margin: auto 0 auto auto;

    ${mediaQuery.between('small', 'medium')} {
        margin-top: ${spaces['0.75bu']};
        margin-bottom: ${spaces['0.75bu']};
    }

    ${({ $isMinimized }) =>
        $isMinimized &&
        css`
            display: none;
        `}
`;

const Title = styled.h3<{ isMinimized: boolean }>`
    grid-area: title;
    font-family: ${typography.fontFamilies.text};
    font-style: normal;
    font-weight: ${typography.fontWeights.bold};
    font-size: ${typography.typeScale.size2}px;
    line-height: ${typography.typeScale.size4}px;
    color: ${colors.grey800};
    margin: auto 0;

    ${({ isMinimized }) =>
        isMinimized &&
        css`
            text-align: center;
        `}
`;

export interface InspirationCollectorProps {
    isUserLoggedIn: boolean;
}

export interface InspirationData {
    designId: string;
    designSrc: string;
    designAlt: string;
    designerId: string;
    categoryKey?: string;
    industryKey?: string;
}

const FIRST_SAVE_PROMPT_TARGET = 5;
const MAX_NUM_DESIGNS = getMaxDesignsInCollector();

export const FeatureInspirationCollector = ({ isUserLoggedIn }: InspirationCollectorProps) => {
    const [inspirations, setInspirations] = useState(getInspirations);
    const [tooltipClose, setToolTipClose] = useState(false);
    const [isMinimized, setIsMinimized] = useState(false);
    const isMobile = useMediaQuery({ maxWidth: breakpoints.medium[1] });

    const isGoodToSave =
        inspirations.length === FIRST_SAVE_PROMPT_TARGET || inspirations.length === MAX_NUM_DESIGNS;

    useEffect(() => {
        const onDesignLikeUpdate = (e: Event) => {
            const detail = (e as CustomEvent).detail;
            if (detail.liked) {
                if (inspirations.length === MAX_NUM_DESIGNS) {
                    setToolTipClose(true);
                    setIsMinimized(false);
                    return;
                }
                setToolTipClose(false);

                if (
                    inspirations.find((data: InspirationData) => data.designId === detail.designId)
                ) {
                    return;
                }

                setInspirations((currentInspirations: InspirationData[]) => [
                    ...currentInspirations,
                    {
                        designId: detail.designId,
                        designSrc: detail.designSrc,
                        designAlt: detail.designAlt,
                        designerId: detail.designerId,
                        categoryKey: detail.categoryKey,
                        industryKey: detail.industryKey,
                    },
                ]);
            } else {
                setInspirations((currentInspirations: InspirationData[]) => {
                    return currentInspirations.filter((inspiration: InspirationData) => {
                        return inspiration.designId !== detail.designId;
                    });
                });
            }
            trackUpdateCollector(detail.liked);
        };
        window.addEventListener(DESIGN_LIKE_UPDATED, onDesignLikeUpdate);
        return () => {
            window.removeEventListener(DESIGN_LIKE_UPDATED, onDesignLikeUpdate);
        };
    }, [inspirations]);

    useEffect(() => {
        saveInspiration(inspirations);
        if (inspirations.length === FIRST_SAVE_PROMPT_TARGET) {
            trackSaveFavoritePrompt('initial');
        }
        if (inspirations.length === MAX_NUM_DESIGNS) {
            trackSaveFavoritePrompt('final');
        }
    }, [inspirations]);

    useEffect(() => {
        if (isGoodToSave) {
            setIsMinimized(false);
        }

        if (!isUserLoggedIn || inspirations.length === 0) {
            return;
        }

        if (inspirations.length > 0 && getLocation() === 'discover') {
            const profileInspirations = inspirations.map((design: InspirationData) => ({
                design: design.designId,
                profile: design.designerId,
            }));

            fetch('/profiles/designs/design_likes', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'x-csrf-token': getCSRFToken(),
                    'x-requested-with': 'XMLHttpRequest',
                },
                body: JSON.stringify(profileInspirations),
            })
                .then((res) => {
                    if (res.status !== 200) {
                        Bugsnag.notify(
                            new Error('request to the bulk like endpoint failed'),
                            (event) => {
                                event.context = 'Bulk like endpoint request failed';
                                event.addMetadata('response', {
                                    statuscode: res.status,
                                    body: JSON.stringify(res.body),
                                });
                            }
                        );
                        return;
                    }
                    setInspirations([]);
                    trackSavedFavoriteCollection();
                    if (isLoadingState()) {
                        redirectTo('/discover?your-likes=true');
                    }
                })
                .catch((err) => {
                    Bugsnag.notify(err);
                });
        }
    }, [isUserLoggedIn, inspirations, isGoodToSave]);

    const onSaveInspirations = () => {
        const industryKeys: string[] = [];
        const categoryKeys: string[] = [];
        inspirations.forEach((inspiration: InspirationData) => {
            if (inspiration.industryKey) {
                industryKeys.push(inspiration.industryKey);
            }
            if (inspiration.categoryKey) {
                categoryKeys.push(inspiration.categoryKey);
            }
        });

        trackClickedSavedFavorites([...new Set(industryKeys)], [...new Set(categoryKeys)]);

        window.setTimeout(() => {
            redirectTo('/login?target=/discover?loading=true');
        }, 300);
    };

    const onItemRemove = (designId: string) => {
        setInspirations(
            inspirations.filter((inspiration: InspirationData) => {
                return inspiration.designId !== designId;
            })
        );

        window.dispatchEvent(
            new window.CustomEvent(INSPIRATION_COLLECTOR_UPDATED, {
                detail: {
                    liked: false,
                    designId: designId,
                },
            })
        );

        trackUpdateCollector(false);
    };

    const handleMinimizeClick = () => {
        trackCollectorToggleAction(isMinimized ? 'maximised' : 'minimised');
        if (!isMinimized && !tooltipClose) {
            setToolTipClose(true);
        }

        setIsMinimized(!isMinimized);
    };

    const tooltipRenderer = () => {
        if (!isGoodToSave || tooltipClose) {
            return null;
        }

        return (
            <TooltipPrompt
                tooltipHeader={
                    inspirations.length === FIRST_SAVE_PROMPT_TARGET
                        ? __('Looking Good!')
                        : __('We love this collection!')
                }
                tooltipBody={
                    inspirations.length === FIRST_SAVE_PROMPT_TARGET
                        ? __(
                              'To save your favorites for future inspo click here to sign up or log in.'
                          )
                        : __(
                              'To save your favorites and add more liked designs, click here to sign up or log in.'
                          )
                }
                onClose={() => setToolTipClose(true)}
            />
        );
    };

    if (isUserLoggedIn) {
        return null;
    }

    return (
        <Wrapper isMinimized={isMinimized}>
            <InspirationCollectorContainer isMinimized={isMinimized}>
                <Title isMinimized={isMinimized}>{__('Your liked designs')}</Title>

                <MinimizeIconContainer isMinimized={isMinimized} onClick={handleMinimizeClick}>
                    {isMinimized ? (
                        <IconUp1 role="button" cursor="pointer" />
                    ) : (
                        <IconDown1 role="button" cursor="pointer" />
                    )}
                </MinimizeIconContainer>

                <DesignContainer isMinimized={isMinimized}>
                    <InspirationItemsContainer
                        inspirationsData={inspirations}
                        onItemRemove={onItemRemove}
                        maxDesignsToDisplay={MAX_NUM_DESIGNS}
                    />
                </DesignContainer>

                <TooltipWrapper>
                    <SaveFavoritesButton
                        appearance="primary"
                        disabled={inspirations.length === 0}
                        size={isMobile ? 'small' : 'medium'}
                        onClick={onSaveInspirations}
                        $isMinimized={isMinimized}
                    >
                        {__('Save favorites')}
                    </SaveFavoritesButton>
                    {tooltipRenderer()}
                </TooltipWrapper>
            </InspirationCollectorContainer>
        </Wrapper>
    );
};
