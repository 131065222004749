import styled from 'styled-components';
import { Box } from '@99designs/emu';
import { mediaQuery } from '@99designs/emu/css';
import { colors, spaces, typography } from '@99designs/emu/settings';
import { IconX1 } from '@99designs/icons';

export interface TooltipProps {
    tooltipHeader: string;
    tooltipBody: string;
    onClose: () => void;
}

const Tooltip = styled.div`
    max-width: 300px;
    background-color: ${colors.pacific};
    color: ${colors.white};
    padding: ${spaces['0.75bu']};
    border-radius: ${spaces['0.5bu']};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    bottom: calc(50% + 31px);
    right: 25px;
    gap: 5px;

    ${mediaQuery.minimum('normal')} {
        bottom: calc(50% + 39px);
        right: 50px;
    }

    :after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-width: 8px;
        border-style: solid;
        border-color: ${colors.pacific} transparent transparent transparent;
        right: 18px;
        bottom: -16px;
    }
`;

const TooltipHeader = styled.div`
    display: flex;
    flex-direction: row;
    font-family: ${typography.fontFamilies.heading};
    font-style: normal;
    font-size: ${spaces['1bu']};
    justify-content: space-between;
    line-height: 19px;
    width: 276px;
`;

const TooltipBody = styled.div`
    font-family: ${typography.fontFamilies.text};
    font-style: normal;
    font-size: 14px;
    line-height: 22px;
`;

const CloseIcon = styled.div`
    color: ${colors.white};
    cursor: pointer;
    height: 28px;
    padding-top: 3px;
    scale: 1.2;
    text-align: center;
    width: 28px;
`;

const TooltipPrompt = ({ tooltipHeader, onClose, tooltipBody }: TooltipProps) => {
    return (
        <Tooltip>
            <TooltipHeader>
                <Box paddingTop="0.5bu">{tooltipHeader}</Box>
                <CloseIcon onClick={onClose}>
                    <IconX1 />
                </CloseIcon>
            </TooltipHeader>
            <TooltipBody>{tooltipBody}</TooltipBody>
        </Tooltip>
    );
};

export default TooltipPrompt;
