import { trackEvent } from '@99designs/tracking';
import { getLocation } from '../util/helpers';

export const trackSavedFavoriteCollection = () => {
    trackEvent('Saved Favorited Designs Collection', {
        saved_status: true,
    });
};

export const trackUpdateCollector = (collectorStatus: boolean) => {
    trackEvent('Updated Designs On Inspiration Collector', {
        added: collectorStatus,
    });
};

export const trackSaveFavoritePrompt = (promptType: string) => {
    trackEvent('Viewed Save Your Favorites Prompt', {
        prompt_type: promptType,
    });
};

export const trackClickedSavedFavorites = (industryKeys: string[], categoryKeys: string[]) => {
    trackEvent('Clicked Save Favorites', {
        industryKeys,
        categoryKeys,
        location: getLocation(),
    });
};

export const trackCollectorToggleAction = (action: string) => {
    trackEvent('Toggled Inspiration Collector Visibility', {
        location: getLocation(),
        action,
    });
};
