import React from 'react';
import ReactDOM from 'react-dom/client';
import {
    FeatureInspirationCollector,
    InspirationCollectorProps,
} from '@99designs/feature-inspiration-collector';
import { MicrofrontendWrapper } from '@99designs/microfrontends';

declare global {
    interface Window {
        mountInspirationCollector: (containerId: string, props: InspirationCollectorProps) => void;
        unmountInspirationCollector: (containerId: string) => void;
    }
}

window.mountInspirationCollector = (containerId: string, props: InspirationCollectorProps) => {
    const root = ReactDOM.createRoot(document.getElementById(containerId) as HTMLElement);
    root.render(
        <MicrofrontendWrapper>
            <FeatureInspirationCollector {...props} />
        </MicrofrontendWrapper>
    );
};

window.unmountInspirationCollector = (containerId: string) => {
    const node = ReactDOM.createRoot(document.querySelector(containerId) as HTMLElement);
    if (node) {
        node.unmount();
    }
};

export {};
