import styled from 'styled-components';
import { mediaQuery } from '@99designs/emu/css';
import { InspirationData } from '../../lib/FeatureInspirationCollector';
import InspirationItem, { OnItemRemove } from '../InspirationItem';

export interface InspirationItemsContainerProps {
    inspirationsData: InspirationData[];
    onItemRemove: OnItemRemove;
    maxDesignsToDisplay: number;
}

const InspirationItemsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    ${mediaQuery.minimum('normal')} {
        max-width: 900px;
    }
    overflow: scroll;
    // hide scrollbar
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
    ::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
    }
`;

const InspirationItemsContainer = ({
    inspirationsData,
    onItemRemove,
    maxDesignsToDisplay,
}: InspirationItemsContainerProps) => {
    return (
        <InspirationItemsWrapper role="list">
            {[...Array(maxDesignsToDisplay)].map((_, i) => {
                return (
                    <InspirationItem
                        inspirationData={inspirationsData[i]}
                        onItemRemove={onItemRemove}
                        key={i}
                    />
                );
            })}
        </InspirationItemsWrapper>
    );
};

export default InspirationItemsContainer;
