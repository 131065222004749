import { InspirationData } from '../lib/FeatureInspirationCollector';

export const saveInspiration = (inspirations: InspirationData[]) => {
    localStorage.setItem('inspirations', JSON.stringify(inspirations));
};

export const getInspirations = (): InspirationData[] => {
    return JSON.parse(localStorage.getItem('inspirations') || '[]');
};

export const getMaxDesignsInCollector = (): number => {
    localStorage.setItem('maxDesignsInCollector', JSON.stringify(10));
    return JSON.parse(localStorage.getItem('maxDesignsInCollector') || '10');
};
